@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .max-w-px-809 {
      max-width: 809px;
    }

    .max-w-px-596 {
      max-width: 596px;
    }

    .width-830 {
      width: 830;
    }

    .min-h-px-404 {
      min-height: 404px;
    }
  }
}

.text-input {
  @apply shadow rounded appearance-none h-14 font-sans text-xl text-blue border border-gray-light leading-tight mb-4 px-3 focus:outline-blue;
}

.title-small {
  @apply font-sans font-bold text-2xl;
}

.title-secondary {
  @apply font-sans font-bold text-3xl
}

.pm-link {
  @apply font-sans text-blue text-xl cursor-pointer flex justify-start items-center leading-6 py-2
}

.link-icon {
  @apply fill-current text-blue cursor-pointer
}